<template>
  <div>
    <div class="ml-auto mr-0" style="width: 100px">
      <b-form-select
        v-model="showChinese"
        :options="languageOptions"
        size="sm"
      ></b-form-select>
    </div>
    <PrivacyPolicyZH v-if="showChinese" />
    <PrivacyPolicyEN v-else />
  </div>
</template>

<script>
import PrivacyPolicyEN from "@/components/privacy_policy/PrivacyPolicyEN.vue";
import PrivacyPolicyZH from "@/components/privacy_policy/PrivacyPolicyZH.vue";
import textConstant from "@/constants/text.js";

export default {
  name: "PrivacyPageView",
  components: {
    PrivacyPolicyEN,
    PrivacyPolicyZH,
  },
  data() {
    let browserLanguageIsChinese = navigator.language === "zh-CN";
    return {
      showChinese: browserLanguageIsChinese,
      languageOptions: [
        {
          value: false,
          text: browserLanguageIsChinese
            ? textConstant.common["zh-CN"].English
            : textConstant.common["others"].English,
        },
        {
          value: true,
          text: browserLanguageIsChinese
            ? textConstant.common["zh-CN"].Chinese
            : textConstant.common["others"].Chinese,
        },
      ],
    };
  },
};
</script>

<style>
body:before {
  background-color: white;
}
h1,
h2,
h3 {
  /* font-size: 0.8em; */
}
</style>
