<template>
  <div class="container mt-3 pb-5">
    <!-- <div class="ml-auto mr-0" style="width: 100px"> -->
    <!--   <b-form-select -->
    <!--     v-model="showChinese" -->
    <!--     :options="languageOptions" -->
    <!--     size="sm" -->
    <!--   ></b-form-select> -->
    <!-- </div> -->
    <TutorialZH></TutorialZH>
    <!-- <PrivacyPolicyZH v-if="showChinese" /> -->
    <!-- <PrivacyPolicyEN v-else /> -->
  </div>
</template>

<script>
import textConstant from "@/constants/text.js";
import TutorialZH from "@/components/tutorial/TutorialZH.vue";

export default {
  name: "TutorialPageView",
  components: {
    TutorialZH,
  },
  data() {
    let browserLanguageIsChinese = navigator.language === "zh-CN";
    return {
      showChinese: browserLanguageIsChinese,
      languageOptions: [
        {
          value: false,
          text: browserLanguageIsChinese
            ? textConstant.common["zh-CN"].English
            : textConstant.common["others"].English,
        },
        {
          value: true,
          text: browserLanguageIsChinese
            ? textConstant.common["zh-CN"].Chinese
            : textConstant.common["others"].Chinese,
        },
      ],
    };
  },
};
</script>

<style>
body:before {
  background-color: white;
}
</style>
