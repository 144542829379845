export default {
  common: {
    "zh-CN": {
      English: "英文",
      Chinese: "中文",
    },
    others: {
      English: "English",
      Chinese: "Chinese",
    },
  },
  homePage: {
    "zh-CN": {
      title: "浪漫笔记",
      subtitle: "构建最强第二大脑，陪伴你随时随地",
      contents: [
        "在当今世界，有一个可以帮助自己思考的第二大脑毫无疑问是一种真正的力量。它以一种结构化的方式帮你客观准确地记录你的一切想法和知识，并在你需要的时候帮你最快搜索出来。你塑造了它，它成长到一定程度后也会反过来塑造你，让你以前所未有的清晰去思考一切你想思考的东西。它会是你忠实的思考伴侣。无论你是学生还是职场人士，只要有自我提升或者输出的需求，那么第二大脑会为你的效率带来数量级级别的提升。",
      ],
      download: "下载",
    },
    others: {
      title: "RomanticNote",
      subtitle:
        "Build your most powerful second brain, access anywhere, anytime",
      contents: [
        "In the mordern world, having a second brain that can help us think is undoubtly a real power. It can help you remember all your thoughts and knowledge correctly and objectively, in a structured way, and quickly give you what you need when you need it. It's shaped by you, and after some time it will in return shape you, help you to think whatever you want to in a kind of clearness that you have never experienced before. It'll be your thinking partner. Whether you are a student or office worker, as long as you have need for self-improvement or output something, having a second brain will boost your productivity dramatically.",
      ],
      download: "Download",
    },
  },
  landingPage: {
    "zh-CN": {
      title: "浪漫笔记",
      subtitle: "构建最强第二大脑，陪伴你随时随地",
      contents: [
        "在当今世界，有一个可以帮助自己思考的第二大脑毫无疑问是一种真正的力量。它以一种结构化的方式帮你客观准确地记录你的一切想法和知识，并在你需要的时候帮你最快搜索出来。你塑造了它，它成长到一定程度后也会反过来塑造你，让你以前所未有的清晰去思考一切你想思考的东西。它会是你忠实的思考伴侣。无论你是学生还是职场人士，只要有自我提升或者输出的需求，那么第二大脑会为你的效率带来数量级级别的提升。",
        "而我目前就在开发这样一款让你构建强大第二大脑的应用：浪漫笔记。人与人之间的联系我把它称作浪漫，笔记与笔记之间，第二大脑与大脑的联系，我也把它称作浪漫。我的目标是在前人不断研究实践的卢曼笔记法上， 把信息之间的联系作为核心中的核心，把这款应用开发成一个强大好用的笔记体系，一个覆盖从输入到输出的完整的工作流，并且可以随时随地在任何设备上访问。",
        "这款应用预计在2022年秋上线，初步适配ios和安卓，之后会加入Apple Watch和Oculus的支持。如果你对这款应用感兴趣，请在下方输入你的常用邮箱，我会在上线的第一时间通知你。这款应用的大部份功能免费，但也会有一些需要付费的高级功能比如超大云储存空间等。为了感谢你在早期的支持，现在输入的邮箱账号将会自动享有永久免费的高级会员，同时将拥有疑问优先解答，功能需求优先满足等一系列特权。此注册通道将在产品上线时关闭。",
      ],
      emailFormIndicator: "请输入邮箱",
      emailSubmitButtonText: "现在注册",
      emailSubmitAlertSuccess: "添加成功",
      emailSubmitAlertError: "添加失败，邮箱格式错误或邮箱已经存在",
    },
    others: {
      title: "RomanticNote",
      subtitle:
        "Build your most powerful second brain, access anywhere, anytime",
      contents: [
        "In the mordern world, having a second brain that can help us think is undoubtly a real power. It can help you remember all your thoughts and knowledge correctly and objectively, in a structured way, and quickly give you what you need when you need it. It's shaped by you, and after some time it will in return shape you, help you to think whatever you want to in a kind of clearness that you have never experienced before. It'll be your thinking partner. Whether you are a student or office worker, as long as you have need for self-improvement or output something, having a second brain will boost your productivity dramatically.",
        "And now I'm working on an application to achive that: RomanticNote. The relations between humans I call it romantic, and the relations between notes, the relations between your second brain and your brain, I also call it romantic. My goal is with absorbing the strength of Luhmann's noting system, which has been researched and practiced by many people, to make this application a powerful and easy-to-use noting system, with connections between information being the most crutial part. It's a complete work flow covering from input to output. And it can be accessed anywhere, anytime, from any of your device.",
        "This app is expected to be launched in the fall, 2022. Available for ios and android initially, after that will be available for Apple watch and Oculus. If you are interested and want to get early access, please enter your email below, I will notify you as soon as it's launched. Most features of this app will be free, but there will also be some premium features such as huge cloud storage, etc. To thank you for your support, the account registered by the email you entered now will get life-long premium membership, also you will enjoy other priviledges like high priority to get response for your questions, high priority to your feature request, etc. This register method will be closed once the product is launched.",
      ],
      emailFormIndicator: "Enter email address...",
      emailSubmitButtonText: "Register Now",
      emailSubmitAlertSuccess: "Successfully submitted",
      emailSubmitAlertError: "Error: email is not valid or already existed",
    },
  },
};
