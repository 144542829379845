<template>
  <div style="height: 100%">
    <img class="bg-img" alt src="../assets/second_brain.jpeg" />
    <div class="masthead">
      <div class="masthead-content text-white">
        <div class="container-fluid px-4 px-lg-0">
          <h1 class="mb-3">{{ pageText.title }}</h1>
          <h3 class="mb-5">{{ pageText.subtitle }}</h3>
          <p
            class="mb-2"
            v-for="(paragraph, index) in pageText.contents"
            :key="index"
          >
            {{ paragraph }}
          </p>
          <h3 class="mt-5" style="text-align: left">
            {{ pageText.download }}
          </h3>
          <div class="row mt-4">
            <div class="col-4">
              <a :href="windowsUrl"
                ><img style="width: 50px" src="../assets/icons/windows-100.png"
              /></a>
              <h6 class="mt-2">Windows</h6>
            </div>
            <div class="col-4">
              <a :href="linuxUrl"
                ><img style="width: 50px" src="../assets/icons/linux-100.png"
              /></a>
              <h6 class="mt-2 ml-2">Linux</h6>
            </div>
            <div class="col-4">
              <a :href="macUrl"
                ><img style="width: 50px" src="../assets/icons/apple-100.png"
              /></a>
              <h6 class="mt-2 ml-2">Mac</h6>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-4">
              <a :href="androidUrl"
                ><img style="width: 50px" src="../assets/icons/android-100.png"
              /></a>
              <h6 class="mt-2">Android</h6>
            </div>
            <div class="col-4">
              <a
                href="https://apps.apple.com/cn/app/%E6%B5%AA%E6%BC%AB%E7%AC%94%E8%AE%B0/id1631320465"
                ><img style="width: 50px" src="../assets/icons/ios-100.png"
              /></a>
              <h6 class="mt-2 ml-2 pl-1">iOS</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textConstant from "@/constants/text.js";

export default {
  name: "HomeView",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      pageText: {},
      baseUrl:
        "https://romanticnote.blob.core.windows.net/app-release/RomanticNote-",
      version: "1.0.4",
    };
  },
  // methods: {},
  computed: {
    baseUrlWithVersion() {
      return this.baseUrl + this.version;
    },
    windowsUrl() {
      return this.baseUrlWithVersion + "-windows-x86_64.exe";
    },
    macUrl() {
      return this.baseUrlWithVersion + "-macos-x86_64.dmg";
    },
    linuxUrl() {
      return this.baseUrlWithVersion + "-linux-x86_64.tar.gz";
    },
    androidUrl() {
      return this.baseUrlWithVersion + "-android-x86_64.apk";
    },
  },
  created() {
    this.pageText =
      textConstant.homePage[navigator.language] ||
      textConstant.homePage["others"];
  },
};
</script>

<style>
html {
  height: 100%;
}

body {
  height: 100%;
  position: relative;
}
body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: -1;
}

.bg-img {
  width: 100%;
}

.masthead {
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.masthead:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
}
.masthead .masthead-content {
  position: relative;
  max-width: 45rem;
  padding-top: 2rem;
  padding-bottom: 4rem;
}
.masthead .masthead-content h1,
.masthead .masthead-content .h1 {
  /* text-align: center; */
  font-size: 2rem;
}
.masthead .masthead-content h3,
.masthead .masthead-content .h3 {
  text-align: right;
  font-size: 1.2rem;
}
.masthead .masthead-content p {
  font-size: 1rem;
}
.masthead .masthead-content p strong {
  font-weight: 700;
}
.masthead .masthead-content .input-group-newsletter input {
  height: 2rem;
  width: 100%;
  font-size: 1rem;
  /* padding: 1rem; */
}
.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  /* letter-spacing: 1px; */
  height: 2rem;
  /* padding: calc(1rem + 2px); */
}

.contact-form {
  margin-top: 3rem;
}

@media (min-width: 1140px) {
  .masthead {
    height: 120%;
    width: 75vw;
    min-height: 0;
    padding-bottom: 0;
  }
  .masthead:before {
    transform: skewX(-9deg);
    transform-origin: top right;
    background-color: rgba(0, 0, 0, 0.85);
  }
  .masthead .masthead-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 5px;
    padding-right: 6rem;
  }
  .masthead .masthead-content h1,
  .masthead .masthead-content .h1 {
    font-size: 3.5rem;
  }
  .masthead .masthead-content h3,
  .masthead .masthead-content .h3 {
    font-size: 1.5rem;
  }
  .masthead .masthead-content p {
    font-size: 1.1rem;
    padding-bottom: 0.1rem;
  }
  .contact-form {
    margin-top: 2rem;
  }
  .bg-img {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-46%) translateY(-50%);
    z-index: 0;
  }
}
@media (min-width: 1400px) {
  .masthead {
    width: 60vw;
  }
}
</style>
