<template>
  <div class="container mt-3 pb-5">
    <h2>隐私政策</h2>
    <p>最后更新：2022 年 6 月 28 日</p>
    <p>
      本隐私政策描述了我们在您使用服务时收集、使用和披露您的信息的政策和程序，并告诉您您的隐私权以及法律如何保护您。
    </p>
    <p>
      我们使用您的个人数据来提供和改进服务。通过使用服务，您同意按照本隐私政策收集和使用信息。本隐私政策是在
      <a
        href="https://www.termsfeed.com/privacy-policy-generator/"
        target="_blank"
        >TermsFeed隐私政策生成器</a
      >的帮助下创建的。
    </p>
    <h3>解释和定义</h3>
    <h4>解释</h4>
    <p>
      首字母大写的词具有以下条件定义的含义。无论以单数还是复数形式出现，下列定义均应具有相同的含义。
    </p>
    <h4>定义</h4>
    <p>就本隐私政策而言：</p>
    <ul>
      <li>
        <p>
          <strong>帐户</strong>
          是指为您创建的唯一帐户，用于访问我们的服务或我们的部分服务。
        </p>
      </li>
      <li>
        <p>
          <strong>关联公司</strong>
          是指控制一方、被一方控制或与一方处于共同控制下的实体，其中&quot;控制&quot;是指拥有
          50%
          或更多的股份、股权或其他有权投票选举董事或其他管理机构的证券的所有权。
        </p>
      </li>
      <li>
        <p>
          <strong>应用程序</strong>
          是指您在任何电子设备上下载的公司提供的软件程序，名为浪漫笔记。
        </p>
      </li>
      <li>
        <p>
          <strong>公司</strong>
          （在本协议中称为&quot;公司&quot;、&quot;我们&quot;、或&quot;我们的&quot;)
          是指我的网站。
        </p>
      </li>
      <!-- <li> -->
      <!-- <p><strong>Cookies</strong> 是网站放置在您的计算机、移动设备或任何其他设备上的小文件，其中包含您在该网站的多种用途中的浏览历史详细信息。</p> -->
      <!-- </li> -->
      <li>
        <p><strong>国家</strong> 指：中国</p>
      </li>
      <li>
        <p>
          <strong>设备</strong>
          是指可以访问服务的任何设备，例如计算机、手机或数字平板电脑。
        </p>
      </li>
      <li>
        <p><strong>个人数据</strong> 是与已识别或可识别个人相关的任何信息。</p>
      </li>
      <li>
        <p><strong>服务</strong> 是指应用程序或网站或两者。</p>
      </li>
      <li>
        <p>
          <strong>服务提供商</strong>
          是指代表公司处理数据的任何自然人或法人。指本公司雇用的第三方公司或个人，以促进本服务、代表本公司提供本服务、执行与本服务相关的服务或协助本公司分析本服务的使用情况。
        </p>
      </li>
      <li>
        <p>
          <strong>使用数据</strong>
          是指自动收集的数据，无论是通过使用服务还是从服务基础设施本身生成的（例如，页面访问的持续时间）。
        </p>
      </li>
      <li>
        <p>
          <strong>网站</strong> 是指我的网站，可从
          <a
            href="https://www.romantic-note.com"
            rel="external nofollow noopener"
            target="_blank"
            >https://www.romantic-note.com</a
          >访问。
        </p>
      </li>
      <li>
        <p>
          <strong>您</strong>
          是指访问或使用服务的个人，或代表该个人访问或使用服务的公司或其他法律实体（如适用）。
        </p>
      </li>
    </ul>
    <h3>收集和使用您的个人数据</h3>
    <h4>收集的数据类型</h4>
    <h5>个人资料</h5>
    <p>
      在使用我们的服务时，我们可能会要求您向我们提供某些可用于联系或识别您的个人身份信息。个人身份信息可能包括但不限于：
    </p>
    <ul>
      <li>
        <p>电子邮件地址</p>
      </li>
      <li>
        <p>使用数据</p>
      </li>
    </ul>
    <h5>使用数据</h5>
    <p>使用服务时会自动收集使用数据。</p>
    <!-- <p>使用数据可能包括您设备的互联网协议地址（例如 IP 地址）、浏览器类型、浏览器版本、您访问的我们服务的页面、您访问的时间和日期、在这些页面上花费的时间、唯一设备等信息标识符和其他诊断数据。</p> -->
    <p>
      使用数据可能包括您访问的我们服务的页面、您访问的时间和日期、在这些页面上花费的时间、唯一设备等信息标识符和其他诊断数据。
    </p>
    <!-- <p>当您通过或通过移动设备访问服务时，我们可能会自动收集某些信息，包括但不限于您使用的移动设备类型、您的移动设备唯一 ID、您的移动设备的 IP 地址、您的手机操作系统、您使用的移动互联网浏览器类型、唯一设备标识符和其他诊断数据。</p> -->
    <!-- <p>当您访问我们的服务或通过或通过移动设备访问服务时，我们还可能收集您的浏览器发送的信息。</p> -->
    <!-- <h5>跟踪技术和 Cookie</h5> -->
    <!--     <p>我们使用 Cookie 和类似的跟踪技术来跟踪我们服务上的活动并存储某些信息。使用的跟踪技术是用于收集和跟踪信息以及改进和分析我们的服务的信标、标签和脚本。我们使用的技术可能包括：</p> -->
    <!--     <ul> -->
    <!--     <li><strong>Cookie 或浏览器 Cookie。</strong> Cookie 是放置在您的设备上的一个小文件。您可以指示您的浏览器拒绝所有 Cookie 或指示何时发送 Cookie。但是，如果您不接受 Cookie，您可能无法使用我们服务的某些部分。除非您已调整您的浏览器设置以拒绝 Cookie，否则我们的服务可能会使用 Cookie。</li> -->
    <!--     <li><strong>闪存 Cookies.</strong> 我们服务的某些功能可能会使用本地存储的对象（或 Flash Cookie）来收集和存储有关您的偏好或您在我们服务上的活动的信息。 Flash Cookie 的管理方式与浏览器 Cookie 所用的浏览器设置不同。有关如何删除闪存Cookies的更多信息，请阅读 &quot;Where can I change the settings for disabling, or deleting local shared objects?&quot;<a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_" rel="external nofollow noopener" target="_blank">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</a></li> -->
    <!--     <li><strong>网络信标。</strong> 我们服务的某些部分和我们的电子邮件可能包含称为网络信标（也称为透明 gif、像素标签和单像素 gif）的小型电子文件，允许公司计算访问过这些页面的用户数或打开电子邮件和其他相关网站的统计数据（例如，记录某个版块的受欢迎程度以及验证系统和服务器的完整性）。</li> -->
    <!--     </ul> -->
    <!--     <p>Cookies可以是&quot;持久&quot;或&quot;会话&quot; Cookies. 。当您离线时，持久性Cookies会保留在您的个人计算机或移动设备上，而会话Cookies会在您关闭网络浏览器后立即删除。您可以在 TermsFeed 网站文章中了解更多关于Cookies的信息。<a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies" target="_blank">TermsFeed website</a></p> -->
    <!--     <p>我们将会话Cookies和持久Cookies用于以下目的：</p> -->
    <!--     <ul> -->
    <!--     <li> -->
    <!--     <p><strong>必要/基本Cookies</strong></p> -->
    <!--     <p>类型：会话Cookies</p> -->
    <!--     <p>管理人：我们</p> -->
    <!--     <p>目的：这些Cookies对于向您提供通过网站可用的服务以及使您能够使用其某些功能至关重要。它们有助于对用户进行身份验证并防止对用户帐户的欺诈性使用。没有这些Cookies，将无法提供您所要求的服务，我们仅使用这些Cookies为您提供这些服务。</p> -->
    <!--     </li> -->
    <!--     <li> -->
    <!--     <p><strong>Cookies 政策/通知接受 Cookies</strong></p> -->
    <!--     <p>类型：持久性 Cookies</p> -->
    <!--     <p>管理人：我们</p> -->
    <!--     <p>目的：这些 Cookies 可识别用户是否已接受在网站上使用 Cookies。</p> -->
    <!--     </li> -->
    <!--     <li> -->
    <!--     <p><strong>功能 Cookies</strong></p> -->
    <!--     <p>类型：持久性 Cookies</p> -->
    <!--     <p>管理人：我们</p> -->
    <!--     <p>目的：这些 Cookies 允许我们记住您在使用本网站时所做的选择，例如记住您的登录详细信息或语言偏好。这些 Cookies 的目的是为您提供更加个性化的体验，并避免您每次使用本网站时都必须重新输入您的偏好。</p> -->
    <!--     </li> -->
    <!--     </ul> -->
    <!--     <p>有关我们使用的 Cookies 以及您对 Cookies 的选择的更多信息，请访问我们的 Cookies 政策或我们隐私政策的 Cookies 部分。</p> -->
    <h4>使用您的个人资料</h4>
    <p>本公司可将个人资料用于以下目的：</p>
    <ul>
      <li>
        <p>
          <strong>提供和维护我们的服务</strong>，包括监控我们服务的使用情况。
        </p>
      </li>
      <li>
        <p>
          <strong>管理您的帐户：</strong>
          管理您作为服务用户的注册。您提供的个人数据可以让您访问作为注册用户可用的服务的不同功能。
        </p>
      </li>
      <li>
        <p>
          <strong>履行合同：</strong>
          您已购买的产品、物品或服务的采购合同或通过服务与我们签订的任何其他合同的制定、遵守和承诺。
        </p>
      </li>
      <li>
        <p>
          <strong>与您联系：</strong>
          通过电子邮件、电话、短信或其他等效形式的电子通信方式与您联系，例如移动应用程序关于功能、产品或合同服务（包括安全更新）的更新或信息通信的推送通知，在必要或合理的情况下执行。
        </p>
      </li>
      <li>
        <p>
          <strong>向您提供：</strong>
          有关我们提供的与您已经购买或询问的类似的其他商品、服务和活动的新闻、特别优惠和一般信息，除非您选择不接收此类信息。
        </p>
      </li>
      <li>
        <p><strong>管理您的请求：</strong> 参加和管理您对我们的请求。</p>
      </li>
      <!-- <li> -->
      <!-- <p><strong>对于业务转让：</strong> 我们可能会使用您的信息来评估或进行合并、剥离、重组、重组、解散或以其他方式出售或转让我们的部分或全部资产，无论是作为持续经营还是作为破产、清算的一部分，或类似程序，其中我们持有的关于我们服务用户的个人数据属于转移的资产。</p> -->
      <!-- </li> -->
      <li>
        <p>
          <strong>用于其他目的：</strong
          >我们可能会将您的信息用于其他目的，例如数据分析、识别使用趋势、确定我们的促销活动的有效性以及评估和改进我们的服务、产品、服务、营销和您的体验。
        </p>
      </li>
    </ul>
    <p>我们可能会在以下情况下分享您的个人信息：</p>
    <ul>
      <li>
        <strong>与服务提供商：</strong>
        我们可能会与服务提供商共享您的个人信息，以监控和分析我们服务的使用情况，以便与您联系。
      </li>
      <!-- <li><strong>对于业务转让：</strong> 我们可能会在与任何合并、出售公司资产、融资或将我们的全部或部分业务收购给另一家公司有关或在谈判期间共享或转让您的个人信息。</li> -->
      <li>
        <strong>与关联公司：</strong>
        我们可能会与我们的关联公司共享您的信息，在这种情况下，我们将要求这些关联公司遵守本隐私政策。关联公司包括我们的母公司和任何其他子公司、合资伙伴或其他公司，我们控制的或与我们共同控制的。
      </li>
      <!-- <li><strong>与业务合作伙伴：</strong> 我们可能会与我们的业务合作伙伴共享您的信息，以便为您提供某些产品、服务或促销活动。</li> -->
      <li>
        <strong>与其他用户：</strong>
        当您在公共区域与其他用户分享个人信息或以其他方式互动时，该等信息可能会被所有用户查看，并可能在外部公开发布。
      </li>
      <li>
        <strong>征得您的同意：</strong
        >我们可能会在征得您同意的情况下出于任何其他目的披露您的个人信息。
      </li>
    </ul>
    <h4>保留您的个人资料</h4>
    <p>
      公司将仅在本隐私政策中规定的目的所需的时间内保留您的个人数据。我们将在必要的范围内保留和使用您的个人数据，以履行我们的法律义务（例如，如果我们需要保留您的数据以遵守适用的法律）、解决争议并执行我们的法律协议和政策。
    </p>
    <p>
      公司还将保留使用数据用于内部分析。使用数据通常会保留较短的时间，除非该数据用于加强安全性或改进我们服务的功能，或者我们在法律上有义务将这些数据保留更长的时间。
    </p>
    <h4>传输您的个人数据</h4>
    <p>
      您的信息（包括个人数据）将在公司的运营办公室以及参与处理的各方所在的任何其他地方进行处理。这意味着该信息可能会被传输到并保存在位于您所在州、省、国家或其他政府管辖区之外的计算机上，这些地区的数据保护法可能与您所在管辖区的不同。
    </p>
    <p>您同意本隐私政策并随后提交此类信息即表示您同意该传输。</p>
    <p>
      公司将采取一切合理必要的措施，确保您的数据得到安全处理并符合本隐私政策，除非有足够的控制措施，包括安全性，否则不会将您的个人数据转移到组织或国家，包括您的数据和其他个人信息。
    </p>
    <h4>披露您的个人资料</h4>
    <!-- <h5>商业交易</h5> -->
    <!--     <p>如果公司涉及合并、收购或资产出售，您的个人数据可能会被转移。我们将在您的个人数据被转移并受不同隐私政策约束之前发出通知。</p> -->
    <h5>执法</h5>
    <p>
      在某些情况下，如果法律要求或响应公共机构（例如法院或政府机构）的有效要求，公司可能需要披露您的个人数据。
    </p>
    <h5>其他法律要求</h5>
    <p>公司可能会披露您的个人数据，相信此类行动对于以下方面是必要的：</p>
    <ul>
      <li>遵守法律义务</li>
      <li>保护和捍卫公司的权利或财产</li>
      <li>防止或调查与服务有关的可能的不当行为</li>
      <li>保护服务用户或公众的人身安全</li>
      <li>防止法律责任</li>
    </ul>
    <h4>您个人数据的安全</h4>
    <p>
      您的个人数据的安全性对我们很重要，但请记住，没有任何一种通过网络传输的方法或电子存储方法是
      100%
      安全的。虽然我们努力使用商业上可接受的方式来保护您的个人数据，但我们不能保证其绝对安全。
    </p>
    <h3>儿童隐私</h3>
    <p>
      我们的服务不针对 13 岁以下的任何人。我们不会故意从 13
      岁以下的任何人那里收集个人身份信息。如果您是父母或监护人并且您知道您的孩子向我们提供了个人数据，请联系我们。如果我们发现我们在未经父母同意的情况下收集了
      13 岁以下任何人的个人数据，我们会采取措施从我们的服务器中删除该信息。
    </p>
    <p>
      如果我们需要将同意作为处理您的信息的法律依据，并且您所在的国家/地区需要父母的同意，我们可能会在收集和使用该信息之前要求您的父母同意。
    </p>
    <h3>链接到其他网站</h3>
    <p>
      我们的服务可能包含指向非我们运营的其他网站的链接。如果您点击第三方链接，您将被引导至该第三方的网站。我们强烈建议您查看您访问的每个网站的隐私政策。
    </p>
    <p>
      我们无法控制任何第三方网站或服务的内容、隐私政策或做法，也不承担任何责任。
    </p>
    <h3>本隐私政策的变更</h3>
    <p>
      我们可能会不时更新我们的隐私政策。我们将通过在此页面上发布新的隐私政策来通知您任何更改。
    </p>
    <p>
      在更改生效之前，我们将通过电子邮件和/或我们服务上的醒目通知通知您，并更新本隐私政策顶部的&quot;最后更新&quot;
      日期。
    </p>
    <p>
      建议您定期查看本隐私政策以了解任何更改。本隐私政策的更改一经发布在此页面上即生效。
    </p>
    <h3>联系我们</h3>
    <p>如果您对本隐私政策有任何疑问，您可以联系我们：</p>
    <ul>
      <li>通过电子邮件：jiongliangzhang@yahoo.com</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyZH",
};
</script>
