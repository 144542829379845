import Vue from "vue";
import axios from "axios";

let config = {
  baseURL: "https://romantic-note.azurewebsites.net",
  // baseURL: "http://127.0.0.1:8000",
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const $axios = axios.create(config);

// $axios.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   },
// );

// $axios.interceptors.response.use(
//   function (response) {
//     // Do something with response data
//     return response.data;
//   },
//   function (error) {
//     switch (error.response?.status) {
//       case 403:
//         break;
//     }
//     return Promise.reject(error);
//   },
// );

Vue.prototype.$axios = $axios;
